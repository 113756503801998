import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  zh: {
    translation: {
      "nav.products": "产品",
      "nav.about": "关于我们",
      "nav.contact": "联系我们",
      "hero.title": "与AI小羊一起奔向未来",
      "hero.subtitle": "用我们尖端的产品，在人工智能的未来世界里畅游",
      "hero.cta": "加入我们",
      "products.title": "我们的产品",
      "products.ai-forwarding": "OpenAI-ALL 中转 API",
      "products.ai-forwarding.desc": "稳定可靠，价格低至官方 1/10，你的 AI 开发利器！",
      "products.browser": "OpenBrowser 远程浏览器（即将开放）",
      "products.browser.desc": "远程访问境外的浏览器，适用于注册或使用境外服务，比如Gmail、OpenAI、Claude等",
      "products.chat": "OpenAI-X 智能聊天",
      "products.chat.desc": "支持所有主流大模型，联网搜索、文件解析、对话分享等功能一应俱全！",
      "products.mirror-ai": "MirrorAI 镜像",
      "products.mirror-ai.desc": "国内直连 ChatGPT/Claude 服务，基础版免费共享，同时提供 Plus 账号等你来体验",
      "products.copilot": "小羊 Copilot",
      "products.copilot.desc": "国内就能高速体验微软 Copilot 的全部功能，聊天、笔记本、插件、图像生成，应有尽有！",
      "products.gemini": "Gemini Pro 智能聊天",
      "products.gemini.desc": "免费体验谷歌最新 AI 大模型 Gemini 1.5 Pro，感受前沿科技的魅力！",
      "products.x-download": "X-Downloader 下载器",
      "products.x-download.desc": "支持下载哔哩哔哩、Twitter、Youtube等平台的媒体资源",
      "about.title": "关于我们",
      "about.content": "我们的AI专家团队致力于降低人工智能的复杂性，使其对个人和企业都易于使用和实用，我们热衷于帮助您和您的企业。",
      "contact.title": "联系我们",
      "contact.content": "准备好用AI来智能化您的业务了吗？不要犹豫，现在就联系我们吧！",
      "contact.cta": "联系我们",
      "footer.copyright": "© 2024 AI小羊。保留所有权利。",
      "social.twitter": "X (Twitter)",
      "social.telegram": "Telegram",
      "language": "语言"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language || navigator.userLanguage,
    fallbackLng: "zh",
    interpolation: {
      escapeValue: false // React already safes from xss
    }
  });

export default i18n;
