// src/App.js
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n-config';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <LandingPage />
    </I18nextProvider>
  );
}

export default App;
